/* You can add global styles to this file, and also import other style files */

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


  // <uniquifier>: Use a unique and descriptive class name
// <weight>: Use a value from 100 to 900

* {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}
   
  body {
    font-family: 'Inter', sans-serif !important;
  }

// body {
//     position: relative;
//     font-family: Arial, Helvetica, sans-serif;
// }

html, body { height: 100%; font-family: 'Inter', sans-serif !important; }


